import { Input } from "@mantine/core";
import { useField } from ".";
import { IFile, ImageUpload } from "../upload/image-upload";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
};

export function ImageField({ name, label, required }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Input.Wrapper label={label} error={error} required={required}>
      <ImageUpload value={value} onChange={(file: IFile) => onChange(file.thumbnail)} error={error} />
    </Input.Wrapper>
  );
}
