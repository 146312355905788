import { Button, Divider, Flex, Grid, Group, LoadingOverlay, Paper, Text } from "@mantine/core";
import { IconMapPinFilled } from "@tabler/icons-react";
import { Draggable, Map } from "pigeon-maps";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { BranchApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";

type IFormData = {
  province: string | undefined;
  district: string | undefined;
  khoroo: string | undefined;
  khoroolol: string | undefined;
  khotkhonBair: string | undefined;
  khashaaDavkhar: string | undefined;
  khaalgaDugaar: string | undefined;
  branchAddress: string | undefined;
  locationLat: string | undefined;
  locationLng: string | undefined;
};

const schema = yup.object({
  province: yup.string().typeError("Заавал бөглөнө!").trim().required("Заавал бөглөнө!"),
  district: yup.string().typeError("Заавал бөглөнө!").trim().required("Заавал бөглөнө!"),
  khoroo: yup.string().trim().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  khoroolol: yup.string().trim().required("Заавал бөглөнө!"),
  khotkhonBair: yup.string().trim().required("Заавал бөглөнө!"),
  khashaaDavkhar: yup.string().trim().required("Заавал бөглөнө!"),
  khaalgaDugaar: yup.string().trim().required("Заавал бөглөнө!"),
  branchAddress: yup.string().max(500, "Ихдээ 500 тэмдэгт авна!").trim().required("Заавал бөглөнө!"),
  locationLat: yup.number().typeError("Тоо авна!").required("Заавал бөглөнө!"),
  locationLng: yup.number().typeError("Тоо авна!").required("Заавал бөглөнө!"),
});

export function SectorAddressForm({ onClose, action, reload, id, justCreatedId }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { zipCodes } = useSelector((state: { general: IGeneral }) => state.general);
  const [anchor, setAnchor] = useState<[number, number]>([action[1]?.locationLat || 47.9221, action[1]?.locationLng || 106.9155]);
  const [zoom, setZoom] = useState(16);

  const handleMapClick = (newPosition: [number, number], setFieldValue: any) => {
    setAnchor(newPosition);
    setFieldValue("locationLat", newPosition[0].toString());
    setFieldValue("locationLng", newPosition[1].toString());
  };

  const handleDragEnd = (newPosition: [number, number], setFieldValue: any) => {
    setAnchor(newPosition);
    setFieldValue("locationLat", newPosition[0].toString());
    setFieldValue("locationLng", newPosition[1].toString());
  };

  const [data] = React.useState<IFormData>({
    province: undefined,
    district: undefined,
    khoroo: undefined,
    khoroolol: undefined,
    khotkhonBair: undefined,
    khashaaDavkhar: undefined,
    khaalgaDugaar: undefined,
    branchAddress: undefined,
    locationLat: undefined,
    locationLng: undefined,
    ...(action && action[1]
      ? {
          province: action[1].province,
          district: action[1].district,
          khoroo: action[1].khoroo,
          khoroolol: action[1].khoroolol,
          khotkhonBair: action[1].khotkhonBair,
          khashaaDavkhar: action[1].khashaaDavkhar,
          khaalgaDugaar: action[1].khaalgaDugaar,
          branchAddress: action[1].branchAddress,
          locationLat: action[1].locationLat,
          locationLng: action[1].locationLng,
        }
      : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await BranchApi.updateAddress(justCreatedId || id, values);
      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      onClose();
      await reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message!);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХАЯГИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <SelectField
                    onChange={() => {
                      setFieldValue("district", undefined);
                      setFieldValue("khoroo", undefined);
                    }}
                    required
                    options={zipCodes.filter((item: any) => item.parent === "0")?.map((c: any, index: number) => ({ label: c.name, value: c.code }))}
                    label="Аймаг, нийслэл"
                    name="province"
                    placeholder="Аймаг, нийслэл"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <SelectField
                    required
                    onChange={() => {
                      setFieldValue("khoroo", undefined);
                    }}
                    disabled={!values.province}
                    options={zipCodes
                      .filter((item: any) => item.parent === values.province)
                      ?.map((c: any, index: number) => ({ label: c.name, value: c.code }))}
                    label="Сум, дүүрэг"
                    name="district"
                    placeholder="Сум, дүүрэг"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <SelectField
                    required
                    disabled={!values.district}
                    options={zipCodes
                      .filter((item: any) => item.parent === values.district)
                      ?.map((c: any, index: number) => ({ label: c.name, value: c.code }))}
                    label="Баг, хороо"
                    name="khoroo"
                    placeholder="Баг, хороо"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="khoroolol" label="Хороолол, гудамж" placeholder="Хороолол, гудамж " required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="khotkhonBair" label="Хотхон, байр" placeholder="Хотхон, байр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="khashaaDavkhar" label="Хашаа, давхар" placeholder="Хашаа, давхар " required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <TextField name="khaalgaDugaar" label="Хаалганы дугаар" placeholder="Хаалганы дугаар " required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextareaField name="branchAddress" label="хаягийн бичгийн тайлбар" placeholder="хаягийн бичгийн тайлбар " required />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="NETWORK-Д ИЛ ХАРАГДАХ МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Paper h={400} w="100%" withBorder>
                    <Map
                      height={400}
                      center={anchor}
                      zoom={zoom}
                      attribution={false}
                      onClick={(e) => handleMapClick(e.latLng, setFieldValue)}
                      onBoundsChanged={({ zoom }) => setZoom(Math.min(Math.max(zoom, 12), 16))}
                      minZoom={12}
                      maxZoom={16}>
                      <Draggable offset={[0, 0]} anchor={anchor} onDragEnd={(e) => handleDragEnd(e, setFieldValue)}>
                        <IconMapPinFilled size={32} color="#ff0000" />
                      </Draggable>
                    </Map>
                  </Paper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                  <Grid>
                    <Grid.Col span={12}>
                      <Text fz={14}>Газрын зураг дээр сонгож тэмдэглэвэл автоматаар уртраг, өргөрөг хадгалагдана.</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextField label="Өргөрөг" name="locationLat" placeholder="Өргөрөг" required />
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <TextField label="Уртраг" name="locationLng" placeholder="Уртраг" required />
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
                <Grid.Col>
                  <Divider h={"lg"} />
                  <Group>
                    <Button color="teal" type="submit">
                      Хадгалах
                    </Button>
                  </Group>
                </Grid.Col>
              </Grid>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}
