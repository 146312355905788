import { ActionIcon, Alert, Avatar, Badge, Box, Button, CloseButton, Collapse, Drawer, Flex, Image, Modal, Tabs, Text, Tooltip } from "@mantine/core";
import { IconBuilding, IconEdit, IconPhoto, IconSpeakerphone } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BusinessApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { MarketingForm } from "../../components/supplier/marketing-form";
import { SupplierInfoForm } from "../../components/supplier/supplier-form";
import { ColumnType, Table } from "../../components/table";
import { Tag } from "../../components/tags";
import RenderText from "../../components/ui/render-text";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";

interface ISupplierProps {
  type: string;
}

export function Supplier({ type }: ISupplierProps) {
  const breadcrumbs = useBreadcrumb();
  const { businessStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [preAlert, setPreAlert] = React.useState<boolean>(true);
  const [id, setId] = React.useState<string | null>(null);
  const [imageOpen, setImageOpen] = useState<any[]>([]);

  const [action, setAction] = React.useState<any>([]);
  const tableRef = React.useRef<any>(null);
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState<string>("default");

  useEffect(() => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {} as Record<string, string>);

    if (cookies.preAlertSupplierHidden === "true") {
      setPreAlert(false);
    }
  }, []);

  const reloadTable = () => {
    tableRef.current.reload();
  };

  const columns = useHeader({
    businessStatus,
    setImageOpen,
    user,
    onClick: (key, record) => {
      switch (key) {
        case "edit":
          setAction(["DOC_FORM", record]);
          setActiveTab("default");
          setDrawerOpen(true);
          return;

        default:
          break;
      }
    },
  });

  const onCloseAll = () => {
    setDrawerOpen(false);
    setAction([]);
    setActiveTab("default");
  };

  const handleClose = () => {
    setPreAlert(false);
    document.cookie = "preAlertSupplierHidden=true; path=/; max-age=" + 365 * 24 * 60 * 60;
  };

  return (
    <PageLayout
      title="Нийлүүлэлтийн бизнес"
      subTitle="Нийлүүлэлтийн бизнесийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Button
          onClick={() => {
            setAction([]);
            setActiveTab("default");
            setDrawerOpen(true);
          }}
          size="sm">
          Бизнес нэмэх
        </Button>,
      ]}>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        {preAlert && (
          <Collapse in={preAlert}>
            <Alert
              styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
              onClose={handleClose}
              radius="md"
              color="red"
              withCloseButton
              title="Анхаар!">
              Та DeHUB Network-р худалдан авагч бизнесүүдтэй хамтран ажиллахын тулд өөрийн ханган нийлүүлэлтийн үйл ажиллагааг “Supplier бизнесийн профайл”-д
              үнэн зөв бүртгэн оруулах шаардлагатай.
            </Alert>
          </Collapse>
        )}
        <Box
          sx={(theme) => ({
            background: theme.white,
            borderRadius: 8,
            border: `1px solid ${theme.colors.gray[2]}`,
            padding: "16px 20px",
          })}
          w={"100%"}>
          <Table ref={tableRef} name="supply.table" filters={{ query: "", type: "SUPPLIER" }} columns={columns} loadData={(data) => BusinessApi.list(data!)} />
        </Box>
      </Flex>

      <Drawer
        styles={{ body: { overflowY: "scroll" } }}
        position="right"
        opened={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        withCloseButton={false}
        padding="xl"
        size="70%">
        <PageLayout extra={[<CloseButton onClick={() => onCloseAll()} key={1} />]} title="Нийлүүлэгч бизнесийн мэдээлэл">
          <Tabs variant="pills" value={activeTab} h="100%">
            <Tabs.List>
              <Tabs.Tab leftSection={<IconBuilding color={activeTab === "default" ? "white" : "gray"} />} value="default">
                <Text c={activeTab === "default" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
              </Tabs.Tab>
              <Tabs.Tab disabled={!action[1] && !id} leftSection={<IconSpeakerphone color={activeTab === "marketing" ? "white" : "gray"} />} value="marketing">
                <Text c={activeTab === "marketing" ? "" : "gray"}>Маркетингийн мэдээлэл</Text>
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="default">
              <SupplierInfoForm
                setId={setId}
                type={"SUPPLIER"}
                onClose={() => onCloseAll()}
                reload={reloadTable}
                actionOutside={action}
                setActiveTab={setActiveTab}
              />
            </Tabs.Panel>
            <Tabs.Panel value="marketing">
              <MarketingForm reload={reloadTable} id={action[1]?.id || id} action={action} onClose={() => onCloseAll()} setActiveTab={setActiveTab} />
            </Tabs.Panel>
          </Tabs>
        </PageLayout>
      </Drawer>
      <Modal title={false} opened={imageOpen[0] === "open"} onClose={() => setImageOpen([])} padding={0} withCloseButton={false} centered>
        <Image src={imageOpen[1]} />
      </Modal>
    </PageLayout>
  );
}

const useHeader = ({
  onClick,
  businessStatus,
  setImageOpen,
  user,
}: {
  onClick: (key: string, record: any) => void;
  businessStatus: any;
  setImageOpen: (e: any) => void;
  user: any;
}): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Tooltip label="Засах">
          <ActionIcon
            color="teal"
            variant="light"
            onClick={() => (user.partner.province ? onClick("edit", record) : message.error("Байгууллагын мэдээлэл хэсэгт хаягийн мэдээлэл оруулна уу."))}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      );
    },
  },
  {
    title: "Лого",
    render: (record) => (
      <Avatar
        style={{ cursor: record?.logo ? "pointer" : "not-allowed" }}
        onClick={() => (record?.logo ? setImageOpen(["open", record?.logo]) : {})}
        src={record?.logo}
        radius="xs"
        component="a">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Партнер нэр",
    dataIndex: "partnerName",
    render: (record) => <RenderText text={record?.partnerName ?? "-"} />,
  },
  {
    title: "Supplier код",
    dataIndex: "refCode",
    render: (record) => <RenderText text={`#${record?.refCode ?? "-"}`} />,
  },
  {
    title: "Бизнесийн нэр",
    dataIndex: "profileName",
    render: (record) => <RenderText text={record?.profileName ?? "-"} />,
  },
  {
    title: "Сектор",
    dataIndex: "bus.name",
    render: (record) => <RenderText text={record?.businessSector?.name ?? "-"} />,
  },
  {
    title: "Дэд сектор",
    dataIndex: "subsector",
    render: (record) => <RenderText text={record?.businessSubSector?.name ?? "-"} />,
  },
  {
    title: "Үндсэн эсэх",
    dataIndex: "isDefault",
    render: (record) => (
      <Badge variant="outline" color={record?.isDefault ? "green.5" : "gray.5"}>
        {record?.isDefault ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Төлөв",
    dataIndex: "businessStatus",
    render: (record) => (
      <Tag
        color={businessStatus.find((item: IReference) => item?.code === record?.businessStatus)?.color ?? "blue"}
        name={businessStatus.find((item: IReference) => item?.code === record?.businessStatus)?.name ?? record?.businessStatus ?? "-"}
      />
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "date",
    render: (record) => <RenderText text={dateTimeFormat(record?.createdAt) ?? "-"} />,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Нийлүүлэлтийн бизнес",
  },
];
