import { Badge, MantineColor } from "@mantine/core";

interface TagProps {
  color: MantineColor;
  name: string;
}

export function Tag({ color, name }: TagProps) {
  const isHexColor = color.startsWith("#");

  return (
    <Badge
      color={isHexColor ? undefined : color}
      style={isHexColor ? { border: `1px solid ${color}`, color } : undefined}
      size="md"
      radius="xl"
      variant="outline"
      styles={{ label: { overflow: "visible" } }}>
      {name}
    </Badge>
  );
}
