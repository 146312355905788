import { Alert, Box, Collapse, Divider, Flex, Grid, Space, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBuilding, IconId, IconMail, IconMapPin, IconPhone, IconUser, IconWorld } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthApi, PartnerApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import AdminInformation from "../../components/partner/admin-information";
import { MainInfoForm } from "../../components/partner/mainInfo-form";
import { ImageUpload } from "../../components/upload/image-upload";
import { IAuth } from "../../interfaces/IAuth";
import { authMe } from "../../store/Auth";
import { message } from "../../utils/message";

export function Partner() {
  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const [preAlert, setPreAlert] = React.useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>("info");
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = document.cookie.split("; ").reduce((acc, cookie) => {
      const [name, value] = cookie.split("=");
      acc[name] = value;
      return acc;
    }, {} as Record<string, string>);

    if (cookies.preAlertHidden === "true") {
      setPreAlert(false);
    }
  }, []);

  const changeLogo = async (e: any) => {
    try {
      await PartnerApi.uploadLogoProfile({ logo: e?.url });
      const auth = await AuthApi.me();
      dispatch(authMe(auth));
      message.success("Партнерийн зураг амжилттай солигдлоо");
    } catch (error: any) {
      message.error(error.message ?? "Алдаа гарлаа");
    }
  };

  const handleClose = () => {
    setPreAlert(false);
    document.cookie = "preAlertHidden=true; path=/; max-age=" + 365 * 24 * 60 * 60;
  };

  return (
    <PageLayout title="Байгууллагын мэдээлэл" breadcrumb={breadcrumbs}>
      <Flex direction="column" gap="md">
        {preAlert && (
          <Collapse in={preAlert}>
            <Alert
              styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
              onClose={handleClose}
              radius="md"
              color="red"
              withCloseButton
              title="Анхаар!">
              Та Дижитал Эра Пэймэнт ХХК-тай байгуулсан гэрээнд холбогдох мэдээллийг өөрчлөх боломжгүй. Мэдээлэл буруу байвал ДЭП ХХК-д хандана уу.
            </Alert>
          </Collapse>
        )}

        <Grid gutter="lg">
          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 4 }}>
            <Box p="lg" className={classes.gridBox}>
              <Flex direction="column" gap="md">
                <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                  <ImageUpload value={user?.partner?.logo} radius={100} size={85} onChange={(e) => changeLogo(e)} />
                  <Text fw={600} size="md" c="#12b886">
                    {user?.partner?.businessName ?? "-"}
                  </Text>
                  <Text size="sm">ТТД: {user?.partner?.regNumber ?? "-"}</Text>
                </Box>
                <Box>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="ХОЛБОО БАРИХ" />
                  <Flex justify="flex-start" gap="sm" direction="column" mt="sm">
                    {user?.partner?.web && (
                      <Flex align="center" gap="sm">
                        <IconWorld color="#12b886" />
                        <Text fw={500} size="sm">
                          {user?.partner?.web ?? "-"}
                        </Text>
                      </Flex>
                    )}
                    <Flex align="center" gap="sm">
                      <IconMail color="#12b886" />
                      <Text fw={500} size="sm">
                        {user?.partner?.email ?? "-"}
                      </Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <IconPhone color="#12b886" />
                      <Text fw={500} size="sm">
                        {user?.partner?.phone ?? "-"}
                      </Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <IconUser color="#12b886" />
                      <Text fw={500} size="sm">
                        {user?.departmentUnitId ?? "-"}
                      </Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <IconId color="#12b886" />
                      <Text fw={500} size="sm">
                        {user?.employeeUnitId ?? "-"}
                      </Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <IconMapPin color="#12b886" />
                      <Text fw={500} size="sm">
                        {user?.partner?.businessAddress ?? "-"}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500, textTransform: "uppercase" } }} label="Худалдан авалтын бизнес" />
                  <Flex gap="sm" direction="column">
                    <Space h="5" />
                    {user?.buyers?.length > 0 ? (
                      user?.buyers?.map((item: any, index: number) => (
                        <Text fw={500} size="sm" key={index}>
                          {item.profileName} /{" "}
                          <Text component="span" c="#12b886">
                            {item.ref_code}
                          </Text>
                        </Text>
                      ))
                    ) : (
                      <Text size="fw" fw={500} c="gray">
                        Бүртгээгүй
                      </Text>
                    )}
                  </Flex>
                </Box>
                <Box>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500, textTransform: "uppercase" } }} label="Нийлүүлэлтийн бизнес" />
                  <Flex gap="sm" direction="column">
                    <Space h="5" />
                    {user?.suppliers?.length > 0 ? (
                      user?.suppliers?.map((item: any, index: number) => (
                        <Text fw={500} size="sm" key={index}>
                          {item.profileName ?? "-"} /{" "}
                          <Text component="span" c="#12b886">
                            {item.ref_code}
                          </Text>
                        </Text>
                      ))
                    ) : (
                      <Text lineClamp={3} fw={500} fz={16} c="gray" ta="start">
                        Бүртгээгүй
                      </Text>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 7, xl: 8 }}>
            <Box p="lg" className={classes.gridBox}>
              <Tabs h="100%" variant="pills" value={activeTab} onChange={(value: any) => setActiveTab(value)}>
                <Tabs.List>
                  <Tabs.Tab leftSection={<IconBuilding color={activeTab === "info" ? "white" : "gray"} />} value="info">
                    <Text c={activeTab === "info" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
                  </Tabs.Tab>
                  <Tabs.Tab value="admin" leftSection={<IconUser color={activeTab === "admin" ? "white" : "gray"} />}>
                    <Text c={activeTab === "admin" ? "" : "gray"}>Админ хэрэглэгч</Text>
                  </Tabs.Tab>
                </Tabs.List>
                <Divider mt="md" mb="md" />
                <Tabs.Panel value="info">
                  <MainInfoForm setActiveTab={setActiveTab} />
                </Tabs.Panel>
                <Tabs.Panel h="100%" value="admin">
                  <AdminInformation setActiveTab={setActiveTab} />
                </Tabs.Panel>
              </Tabs>
            </Box>
          </Grid.Col>
        </Grid>
      </Flex>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Байгууллагын мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  root: {
    padding: theme.spacing.xl,
  },
  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
  systemUserBtn: {
    height: "48px",
  },
  stars: { display: "flex", alignItems: "center", color: "#868e96", fontWeight: 700, fontSize: 14 },
  adminIcon: {
    color: theme.colors.red[8],
  },
  preInfo: {
    display: "flex",
    width: "100%",
    gap: 12,
    color: theme.colors.gray[6],
  },
  preInfoIcon: {
    height: 24,
    width: 24,
    padding: 0,
  },
  preInfoButton: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    width: "100%",
    gap: "12px",
    cursor: "pointer",
    color: theme.colors.gray[6],
    backgroundColor: "white",
    "&:hover": {
      color: theme.colors.teal[8],
      backgroundColor: "white",
    },
  },
}));
