import { Box, Button, Divider, Flex, Grid, Image, Input, LoadingOverlay } from "@mantine/core";
import { IconChevronsLeft, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { BusinessApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { message } from "../../utils/message";
import { Form } from "../form";
import { ImageField } from "../form/image-field";
import { RadioGroupField } from "../form/radiogroup-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { MultiImageUpload } from "../upload/multi-image-upload";

type IFormData = {
  profileName: string | undefined;
  profileNameEng: string | undefined;
  profileBanners: any | undefined;
  logo: string | undefined;
  profileInfo: string | undefined;
  isConfirmed: boolean | string | undefined;
};

const schema = yup.object({
  profileName: yup
    .string()
    .required("Заавал бөглөнө!")
    .matches(/^[А-Яа-я0-9|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна"),
  profileNameEng: yup
    .string()
    .transform((value) => (value === "" ? null : value))
    .matches(/^[A-Za-z\s-.]{2,256}$/, "Зөвхөн латин үсэг авна")
    .nullable()
    .optional(),
  profileBanners: yup.array().max(5, "Ихдээ 5 зураг оруулна уу").nullable(),
  logo: yup.string().optional(),
  profileInfo: yup.string().max(500, "Ихдээ 500 тэмдэгт авна!").trim().optional(),
  isConfirmed: yup.boolean().nullable(),
});

export function MarketingForm({ onClose, action, reload, id, setActiveTab }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const [data, setFormData] = React.useState<IFormData>({
    profileName: user?.partner?.businessName || undefined,
    profileNameEng: undefined,
    profileBanners: [],
    logo: undefined,
    profileInfo: user?.partner?.businessName || undefined,
    isConfirmed: "true",
    ...(action && action[1]
      ? {
          profileName: action[1].profileName,
          profileNameEng: action[1].profileNameEng,
          profileBanners: action[1].profileBanners
            ?.map((c: any) => ({
              url: c.url,
              isMain: c.isMain || false,
            }))
            .reduce((acc: any[], cur: any) => {
              if (cur.isMain) {
                acc.forEach((item) => (item.isMain = false));
                cur.isMain = true;
              }
              acc.push(cur);
              return acc;
            }, []),
          profileInfo: action[1].profileInfo,
          logo: action[1].logo,
          isConfirmed: action[1]?.isConfirmed?.toString(),
        }
      : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        isConfirmed: JSON.parse(values?.isConfirmed as string),
        profileNameEng: values.profileNameEng ?? "",
        profileBanners: Array.isArray(values?.profileBanners) && values.profileBanners.length > 0 ? values.profileBanners : null,
      };

      await BusinessApi.profile(id, payload);
      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      onClose();
      reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message!);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <Flex sx={{ position: "relative" }} direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            <Grid>
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="NETWORK-Д ХАРАГДАХ МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField
                  name="profileName"
                  label="Бизнесийн нэр"
                  placeholder="Бизнесийн нэрээ оруулна уу"
                  onChangeCustom={(e: string) => setFieldValue("profileInfo", e)}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField name="profileNameEng" label="Бизнесийн нэр /Latin/" placeholder="Бизнесийн нэр /Latin/" />
              </Grid.Col>
              <Grid.Col span={12}>
                <Input.Wrapper label="Бизнесийн баннер зураг">
                  {(() => {
                    const image = data?.profileBanners?.find((i: any) => i.isMain);
                    return image ? (
                      <Box
                        w={280}
                        my={10}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #e3e3e3",
                          borderRadius: 5,
                          overflow: "hidden",
                        }}>
                        <Image src={image?.thumbnail} height={160} fit="cover" />
                      </Box>
                    ) : (
                      <Box
                        w={280}
                        h={160}
                        my={10}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #e3e3e3",
                          borderRadius: 5,
                        }}>
                        <IconPhoto size={46} color="gray" />
                      </Box>
                    );
                  })()}
                  <MultiImageUpload
                    initialData={values.profileBanners}
                    max={5}
                    onChange={(images) => {
                      const updatedImages = images.map((image) => ({
                        ...image,
                        isMain: image.selected || false,
                      }));

                      const mainImageIndex = updatedImages.findIndex((img) => img.isMain);
                      const finalImages = updatedImages.map((img, index) => ({
                        ...img,
                        isMain: index === mainImageIndex,
                      }));

                      setFormData((state) => ({ ...state, profileBanners: finalImages }));
                      setFieldValue(
                        "profileBanners",
                        finalImages.map((c: any) => ({ url: c.url, isMain: c.isMain })),
                      );
                    }}
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Input.Wrapper label="Лого зураг">
                  <Box sx={{ position: "relative" }}>
                    {values.logo ? (
                      <Box
                        w={200}
                        my={10}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #e3e3e3",
                          borderRadius: 5,
                          overflow: "hidden",
                        }}>
                        <Image height={150} alt="" src={values.logo} fit="cover" />
                      </Box>
                    ) : (
                      <Box
                        my={10}
                        w={200}
                        h={150}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid #e3e3e3",
                          borderRadius: 5,
                          overflow: "hidden",
                        }}>
                        <IconPhoto size={32} color={"gray"} />
                      </Box>
                    )}
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={4}>
                <ImageField name="logo" label="" required={false} />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextareaField max={500} placeholder="Бизнесийн товч танилцуулга ..." name="profileInfo" label="Бизнесийн товч танилцуулга" required={false} />
              </Grid.Col>
              <Grid.Col span={12}>
                <RadioGroupField
                  label="Идэвхжүүлэх эсэх"
                  name="isConfirmed"
                  options={[
                    { label: "Тийм", value: "true" },
                    { label: "Үгүй", value: "false" },
                  ]}
                />
              </Grid.Col>
            </Grid>

            <Divider />

            <Flex direction="row" justify="space-between">
              <Button onClick={() => setActiveTab("default")} variant="outline" leftSection={<IconChevronsLeft />}>
                Буцах
              </Button>
              <Button type="submit">Хадгалах</Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
