import { Button, Divider, Flex, Grid, Group, LoadingOverlay, TextInput } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCheck, IconEdit, IconPlayerTrackNext, IconX } from "@tabler/icons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi, PartnerApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { authMe } from "../../store/Auth";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";

type IFormData = {
  legalEntityType: string | undefined;
  equityType: string | undefined;
  country: string | undefined;
  classification: string | undefined;
  logo: string | undefined;
  businessName: string | undefined;
  businessNameEng: string | undefined;
  stateRegNum: string | undefined;
  stateRegDate: string | undefined;
  regNumber: string | undefined;
  isVatPayer: string | undefined;
  web: string | undefined;
  email: string | undefined;
  email2: string | undefined;
  phone: string | undefined;
  phone2: string | undefined;
  fbLink: string | undefined;
  province: string | undefined;
  district: string | undefined;
  khoroo: string | undefined;
  khotkhonBair: string | undefined;
  khoroolol: string | undefined;
  khashaaDavkhar: string | undefined;
  khaalgaDugaar: string | undefined;
  businessAddress: string | undefined;
  locationLat: string | undefined;
  locationLng: string | undefined;
  location: any;
  partnerType: string | undefined;
};

const schema = yup.object({
  legalEntityType: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  equityType: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  country: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  classification: yup.string().required("Заавал бөглөнө!"),
  logo: yup.string().nullable(),
  businessName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн крил үсэг авна!")
    .required("Заавал бөглөнө!"),
  businessNameEng: yup
    .string()
    .matches(/^[A-Za-z\s-.]{2,256}$/, "Зөвхөн латин үсэг авна!")
    .required("Заавал бөглөнө!"),
  regNumber: yup.string().when(["partnerType"], ([type]) => {
    if (type === "CITIZEN") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Формат буруу байна!")
        .min(10, "Регистрийн дугаар буруу байна!");
    } else if (type === "COMPANY") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[0-9]{7}$/, "Формат буруу байна!")
        .min(7, "Дугаар 7 оронтой байна!")
        .max(7, "Дугаар 7 оронтой байна!");
    }
    return yup.string().nullable();
  }),
  web: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(
      /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
      "Вэб url биш байна!",
    )
    .nullable(),
  email: yup.string().email("Цахим шуудан байна!").required("Заавал бөглөнө!"),
  email2: yup.string().email("Цахим шуудан байна!").nullable(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .typeError("Зөвхөн дугаар оруулна уу")
    .required("Заавал бөглөнө!"),
  phone2: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .nullable(),
  fbLink: yup
    .string()
    .transform((value) => (value ? value.trim() : null))
    .matches(/^https?:\/\/(www\.)?facebook\.com\/[a-zA-Z0-9._-]+\/?$/i, "Facebook URL биш байна!")
    .nullable(),
  province: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  district: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  khoroo: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  khotkhonBair: yup.string().required("Заавал бөглөнө!"),
  khoroolol: yup.string().required("Заавал бөглөнө!"),
  khashaaDavkhar: yup.string().required("Заавал бөглөнө!"),
  khaalgaDugaar: yup.string().required("Заавал бөглөнө!"),
  businessAddress: yup.string().required("Заавал бөглөнө!"),
  locationLat: yup.number().typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
  locationLng: yup.number().typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
  partnerType: yup.string().nullable(),
});

export function MainInfoForm({ setActiveTab }: any) {
  const [action, setAction] = React.useState<string[]>([]);
  const { equityTypes, legalEntityTypes, zipCodes } = useSelector((state: { general: IGeneral }) => state.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const [data] = React.useState<IFormData>({
    legalEntityType: undefined,
    equityType: undefined,
    country: undefined,
    classification: undefined,
    logo: undefined,
    businessName: undefined,
    businessNameEng: undefined,
    stateRegNum: undefined,
    stateRegDate: undefined,
    regNumber: undefined,
    isVatPayer: undefined,
    web: undefined,
    email: undefined,
    email2: undefined,
    phone: undefined,
    phone2: undefined,
    fbLink: undefined,
    province: undefined,
    district: undefined,
    khoroo: undefined,
    khotkhonBair: undefined,
    khoroolol: undefined,
    khashaaDavkhar: undefined,
    khaalgaDugaar: undefined,
    businessAddress: undefined,
    locationLat: undefined,
    locationLng: undefined,
    location: undefined,
    partnerType: user?.partner?.type,
    ...{ ...user?.partner, isVatPayer: user?.partner?.isVatPayer || false },
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const data = {
        legalEntityType: values?.legalEntityType,
        equityType: values?.equityType,
        country: values?.country,
        classification: values?.classification,
        logo: values?.logo,
        businessName: values?.businessName,
        businessNameEng: values?.businessNameEng,
        stateRegNum: values?.stateRegNum,
        regNumber: values?.regNumber,
        web: values?.web,
        email: values?.email,
        email2: values?.email2,
        phone: values?.phone,
        phone2: values?.phone2,
        fbLink: values?.fbLink,
        province: values?.province,
        district: values?.district,
        khoroo: values?.khoroo,
        khotkhonBair: values?.khotkhonBair,
        khoroolol: values?.khoroolol,
        khashaaDavkhar: values?.khashaaDavkhar,
        khaalgaDugaar: values?.khaalgaDugaar,
        businessAddress: values?.businessAddress,
        locationLat: values?.locationLat,
        locationLng: values?.locationLng,
        location: values?.location,
        partnerType: values?.partnerType,
      };
      await PartnerApi.updateInfo(data);
      const auth = await AuthApi.me();
      dispatch(authMe(auth));
      setAction([]);
      setLoading(false);
      message.success("Үйлдэл амжилттай.");
    } catch (error: any) {
      setLoading(false);
      error.message && message.error(error.message);
    }
  };

  const onCancel = () => {
    setAction([]);
  };

  function getValidDate(dateString?: string | null): Date | null {
    if (dateString) {
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    return null;
  }

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <Flex style={{ position: "relative" }} direction="column" justify="space-between" gap="md">
            <LoadingOverlay visible={loading} />
            <input type="hidden" name="partnerType" value={user?.partner?.type} />
            <Grid gutter="sm">
              <Grid.Col span={12}>
                <Divider color="#e5e5e5" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextInput disabled label="Байгууллагын код" placeholder="Байгууллагын код" value={user?.partnerRefCode} />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <DatePickerInput
                  valueFormat="MM-DD-YYYY HH:mm"
                  disabled
                  label="Бүртгэсэн огноо, цаг"
                  value={getValidDate(user?.partnerCreatedAt)}
                  placeholder="Бүртгэсэн огноо, цаг"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField
                  disabled={user?.partner?.registerStatus === "VERIFIED" || user?.partner?.registerStatus === "ADMIN_VERIFIED"}
                  label="Регистр/Татвар төлөгчийн дугаар"
                  name="regNumber"
                  placeholder="Регистр/Татвар төлөгчийн дугаар"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={true} label="Аж ахуйн нэгжийн нэр" name="businessName" placeholder="Аж ахуйн нэгжийн нэр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={true} label="Аж ахуйн нэгжийн нэр/Латин/" name="businessNameEng" placeholder="Аж ахуйн нэгжийн нэр/Латин/" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={legalEntityTypes.map((c: any) => ({ label: c.name, value: c.code }))}
                  disabled={true}
                  label="Аж ахуйн нэгжийн хэлбэр"
                  name="legalEntityType"
                  placeholder="Аж ахуйн нэгжийн хэлбэр"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={[{ label: "Монгол", value: "Монгол" }]}
                  disabled={true}
                  label="Улсын харьяалал"
                  name="country"
                  placeholder="Улсын харьяалал"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={equityTypes.map((c: any) => ({ label: c.name, value: c.code }))}
                  disabled={true}
                  label="Өмчийн хэлбэр"
                  name="equityType"
                  placeholder="Өмчийн хэлбэр"
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="#e5e5e5" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={true} label="Утасны дугаар №1" name="phone" placeholder="Утасны дугаар №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField nullable disabled={action[0] !== "edit"} label="Утасны дугаар №2" name="phone2" placeholder="Утасны дугаар №2" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={true} label="И-мэйл хаяг №1" name="email" placeholder="И-мэйл хаяг №1" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField nullable disabled={action[0] !== "edit"} label="И-мэйл хаяг №2" name="email2" placeholder="И-мэйл хаяг №2" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField nullable disabled={action[0] !== "edit"} label="Веб сайтын хаяг" name="web" placeholder="Веб сайтын хаяг" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField nullable disabled={action[0] !== "edit"} label="Facebook хаяг" name="fbLink" placeholder="Facebook хаяг" />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="#e5e5e5" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХАЯГ" />
              </Grid.Col>

              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={zipCodes?.filter((c: any) => c.parent === "0").map((c: any) => ({ label: c.name, value: c.code })) || []}
                  disabled={action[0] !== "edit"}
                  label="Аймаг, нийслэл"
                  name="province"
                  placeholder="Аймаг, нийслэл"
                  onChange={() => {
                    setFieldValue("district", undefined);
                    setFieldValue("khoroo", undefined);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={zipCodes?.filter((item: any) => item.parent === values.province).map((c: any) => ({ label: c.name, value: c.code })) || []}
                  disabled={action[0] !== "edit" || !values.province}
                  label="Сум, дүүрэг"
                  name="district"
                  placeholder="Сум, дүүрэг"
                  onChange={() => {
                    setFieldValue("khoroo", undefined);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <SelectField
                  options={zipCodes?.filter((item: any) => item.parent === values.district).map((c: any) => ({ label: c.name, value: c.code })) || []}
                  disabled={action[0] !== "edit" || !values.district}
                  label="Баг, хороо"
                  name="khoroo"
                  placeholder="Баг, хороо"
                />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={action[0] !== "edit"} label="Хороолол, гудамж" name="khoroolol" placeholder="Хороолол, гудамж" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={action[0] !== "edit"} label="Хотхон, байр" name="khotkhonBair" placeholder="Хотхон, байр" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={action[0] !== "edit"} label="Хашаа, давхар" name="khashaaDavkhar" placeholder="Хашаа, давхар" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, md: 6 }}>
                <TextField disabled={action[0] !== "edit"} label="Хаалганы дугаар" name="khaalgaDugaar" placeholder="Хаалганы дугаар" />
              </Grid.Col>
              <Grid.Col span={{ xs: 12 }}>
                <TextareaField disabled={action[0] !== "edit"} label="Хаягийн бичгийн тайлбар" name="businessAddress" placeholder="хаягийн бичгийн тайлбар" />
              </Grid.Col>

              {/* <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="БАЙРШИЛ" />
              </Grid.Col> */}

              {/* <Grid.Col span={12}>
                <Box h={300} w="100%">
                  <GoogleMap
                    setFieldValue={action[0] === "edit" ? setFieldValue : null}
                    center={{ lat: Number(values?.locationLat), lng: Number(values.locationLng) }}
                  />
                </Box>
              </Grid.Col> */}
              {/* <Grid.Col span={{ base: 12 }}>
                <Grid>
                  <Grid.Col span={12}>
                    <Text fz={14}>Газрын зураг дээр сонгож тэмдэглэвэл автоматаар уртраг, өргөрөг хадгалагдана.</Text>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextField disabled={action[0] !== "edit"} label="Өргөрөг" name="locationLat" placeholder="Өргөрөг" />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextField disabled={action[0] !== "edit"} label="Уртраг" name="locationLng" placeholder="Уртраг" />
                  </Grid.Col>
                </Grid>
              </Grid.Col> */}
              <Grid.Col>
                <Divider />
                <Group mt={"md"} justify="space-between">
                  <Flex justify="end" gap="md">
                    {action[0] === "edit" ? (
                      <Flex gap="md">
                        <Button leftSection={<IconX />} color="gray" variant="outline" onClick={() => onCancel()}>
                          Болих
                        </Button>
                        <Button leftSection={<IconCheck />} color="teal" type="submit">
                          Хадгалах
                        </Button>
                      </Flex>
                    ) : (
                      <Button variant="outline" leftSection={<IconEdit size={20} />} onClick={() => setAction(["edit"])}>
                        Засах
                      </Button>
                    )}
                  </Flex>
                  {user?.isPartnerConfirmed ? null : (
                    <Flex direction="row" justify="end">
                      <Button onClick={() => setActiveTab("admin")} rightSection={<IconPlayerTrackNext size={20} />} disabled={action[0] === "edit"}>
                        Үргэлжлүүлэх
                      </Button>
                    </Flex>
                  )}
                </Group>
              </Grid.Col>
            </Grid>
          </Flex>
        );
      }}
    </Form>
  );
}
