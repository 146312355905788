import { Box, Button, Grid, Group, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { forwardRef } from "react";
import * as yup from "yup";
import { PubGeneralApi } from "../../apis";
import { IReference } from "../../interfaces/IReference";
import { dateTimeFormat } from "../../utils/date";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { CheckboxRadioField } from "../form/checkbox-radio-field";
import { TextField } from "../form/text-field";

type IFormData = {
  type: string | undefined;
  regNumber: string | undefined | number;
  businessName: string | undefined;
  firstName: string | undefined;
  phone: string | undefined;
  email: string | undefined;
};

const schema = yup.object({
  type: yup.string().required("Заавал бөглөнө!"),
  businessName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё|\s|.|-]{2,255}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё|\s|.|-]{2,100}$/, "Зөвхөн кирил үсэг авна")
    .required("Заавал бөглөнө!"),
  email: yup.string().email("Email оруулна уу").required("Заавал бөглөнө!").nullable(),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Зөвхөн дугаар оруулна уу")
    .required("Заавал бөглөнө!")
    .nullable(),
  regNumber: yup
    .string()
    .when("type", {
      is: "CITIZEN",
      then: (schema) => schema.matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Зөвхөн регистрийн дугаар оруулна уу!").required("Заавал бөглөнө!"),
    })
    .when("type", {
      is: "COMPANY",
      then: (schema) => schema.matches(/^[0-9]{7}$/, "Зөвхөн компанийн регистр оруулна уу!").required("Заавал бөглөнө!"),
    })
    .required("Заавал бөглөнө!"),
});

interface PartnerType {
  code: string;
  name: string;
}

export const RegisterForm = forwardRef<IFormRef<any>, { setAction: any; initData: object | any }>(({ setAction, initData }, ref) => {
  const { partnerTypes } = initData;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { classes } = useStyles();

  const [data] = React.useState<IFormData>({
    type: "COMPANY",
    regNumber: undefined,
    businessName: undefined,
    firstName: undefined,
    phone: undefined,
    email: undefined,
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);

    try {
      let res = await PubGeneralApi.register({
        ...values,
        phone: values.phone?.toString(),
        regNumber: `${values.regNumber}`,
      });
      message.success(`Таны ${values?.email && values?.email} хаягт бүртгэл баталгаажуулах и-мэйл илгээлээ. ` + dateTimeFormat(`${new Date()}`));
      setAction(["verify", { ...values, phone: values.phone?.toString(), verifyId: res.verifyId }]);
    } catch (error: any) {
      message.error(error.message ?? "Үйлдэл амжилтгүй.");
    }

    setLoading(false);
  };

  return (
    <Form ref={ref} initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {({ values }) => {
        return (
          <Box className={classes.container}>
            <Grid>
              <Grid.Col span={12}>
                <CheckboxRadioField
                  size="md"
                  name="type"
                  label={<Text c="white">Партнерийн төрөл</Text>}
                  options={partnerTypes
                    ?.filter((item: PartnerType) => item.code !== "CITIZEN")
                    .map((item: IReference) => {
                      return {
                        label: <Text c="white">{item.name}</Text>,
                        value: item.code,
                      };
                    })}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                {values.type === "COMPANY" ? (
                  <TextField
                    type="number"
                    disabled={!values.type}
                    size="md"
                    className={classes.field}
                    label="Татвар төлөгчийн дугаар"
                    name="regNumber"
                    placeholder="Татвар төлөгчийн дугаар"
                    required
                  />
                ) : (
                  <TextField
                    disabled={!values.type}
                    size="md"
                    className={classes.field}
                    label="Регистрийн дугаар"
                    name="regNumber"
                    placeholder="Регистрийн дугаар"
                    required
                  />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField size="md" className={classes.field} name="businessName" placeholder="Аж ахуйн нэгжийн нэр" label="Аж ахуйн нэгжийн нэр" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField size="md" className={classes.field} name="firstName" placeholder="Өөрийн нэр" label="Өөрийн нэр" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField size="md" className={classes.field} name="email" label="И-мэйл" placeholder="И-мэйл" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField size="md" className={classes.field} type="number" name="phone" label="Гар утас" placeholder="Гар утас" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Group my={20}>
                  <Button loading={loading} variant="gradient" gradient={{ from: "red", to: "#051139" }} size="md" w="100%" type="submit">
                    Бүртгүүлэх
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          </Box>
        );
      }}
    </Form>
  );
});

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
  },

  labelText: {
    fontWeight: 400,
    fontSize: 16,
  },

  field: {
    label: {
      marginBottom: 5,
      color: theme.white,
    },
  },

  forgotText: {
    color: "white",
    marginTop: 10,
  },

  highLightText: {
    fontWeight: 700,
    marginLeft: 10,
    marginRight: 10,
  },
}));
