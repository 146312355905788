import { Box, Button, Divider, Flex, Grid, Text } from "@mantine/core";
import { IconPlayerTrackPrev } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";

type AdminInformationProps = {
  setActiveTab: any;
};

const AdminInformation = ({ setActiveTab }: AdminInformationProps) => {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  return (
    <Grid>
      <Grid.Col span={12}>
        <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Админы регистр №
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.registerNo || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Ажилтны ургийн овог
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.familyName || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Ажилтны эцэг эхийн нэр
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.lastName || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Ажилтны өөрийн нэр
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.firstName || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Харьяалах нэгжийн нэр
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.departmentSubUnitId || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Албан тушаалын нэр
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.employeeUnitId || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={12}>
        <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХОЛБОО БАРИХ" />
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Утас №1
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.phone || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Утас №2
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.phone2 || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Цахим шуудан №1
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.email || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Цахим шуудан №2
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.email2 || "-"}
          </Text>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
        <Box>
          <Text size="sm" c="rgb(98 86 86)">
            Facebook
          </Text>
          <Text size="sm" fw={500}>
            {user?.admin?.socialFbLink || "-"}
          </Text>
        </Box>
      </Grid.Col>
      {user?.isPartnerConfirmed ? null : (
        <Grid.Col span={12}>
          <Flex justify="flex-end">
            <Button variant="outline" onClick={() => setActiveTab("info")} leftSection={<IconPlayerTrackPrev size={20} />}>
              Буцах
            </Button>
          </Flex>
        </Grid.Col>
      )}
    </Grid>
  );
};

export default AdminInformation;
