import { ActionIcon, Menu } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconDotsVertical } from "@tabler/icons-react";
import { JSX } from "react";

type Props = {
  extra: { [key: string]: string | JSX.Element };
  onClick: (key: string) => void;
};

export function RowAction({ extra, onClick }: Props) {
  const { classes } = useStyles();

  return (
    <Menu zIndex={100} transitionProps={{ duration: 150, exitDuration: 150, transition: "pop" }} position="left">
      <Menu.Target>
        <ActionIcon size={32} radius={100} className={classes.control}>
          <IconDotsVertical size={18} stroke={2} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {Object.keys(extra).map((key, index) => {
          return (
            <Menu.Item key={index} onClick={() => onClick(key)}>
              {extra[key]}
            </Menu.Item>
          );
        })}
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles(() => ({
  control: {
    border: 0,
  },
}));
