import { AppShell, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { JSX, useState } from "react";
import { Outlet } from "react-router-dom";
import { useScreenDetector } from "../screen-detector";
import { Breadcrumb } from "./breadcrumb";
import { Header } from "./header";
import { Navigation } from "./navbar";

export function Layout() {
  const { isMobile, isTablet } = useScreenDetector();
  const [opened, setOpened] = useState<boolean>(false);

  return (
    <AppShell padding="md" header={{ height: { base: 60, sm: 70, md: 80 } }} navbar={{ width: { lg: 120 }, breakpoint: "lg", collapsed: { mobile: !opened } }}>
      <Header opened={opened} setOpened={setOpened} />
      {opened || (!isMobile && !isTablet) ? <Navigation opened={opened} setOpened={setOpened} /> : null}
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
}

type PageProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  loading?: boolean;
  breadcrumb?: { to?: string; label: string }[];
};

export function PageLayout({ title, subTitle, breadcrumb, extra, children, loading = false }: PageProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pageLayout}>
      <LoadingOverlay visible={loading} />
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>
          {(extra || [])?.map((item, index) => (
            <div key={index}>{item}</div>
          ))}
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

type SectionProps = {
  left?: JSX.Element[];
  right?: JSX.Element[];
};

export function PageSection({ left, right }: SectionProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pageSection}>
      <div className={classes.sectionElement}>{left}</div>
      <div className={classes.sectionElement}>{right}</div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    position: "relative",
  },
  titleLayout: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    color: theme.colors.gray[6],
    marginBottom: 0,
  },
  extra: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  pageSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  sectionElement: {
    display: "flex",
    gap: 15,
  },
}));
